jQuery($ => {

    const aboutSwiper = $("#aboutSwiper");
    if (aboutSwiper) {
        let swiper = new Swiper(aboutSwiper, {
            slidesPerView: 1,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            speed: 800,
            autoplay: {
                delay: 5000
            }
        });
    }
});
