jQuery($ => {

    // variables
    const resultsWrapper = document.querySelector(".m-projects__row");
    const loaderWrap = $("#projectLoaderWrap");
    const postCount = $('.m-projects').data('found');
    let currentPostCount = $(".m-projects .e-box").length;

    if(resultsWrapper) {
        let infinityLoadMore = true;

        // functions
        function loadAjaxProjects() {
            return new Promise(resolve => {
                const nextPosts = $(".m-projects__row");
                const offset = $('.m-projects .e-box').length;

                $.ajax({
                    url: main.ajax_url,
                    type: "post",
                    data: {
                        action: "load_projects",
                        offset: offset
                    },
                    beforeSend: function (e) {
                        loaderWrap.fadeIn(200);
                    },
                    complete: function (e) {
                        resolve();
                        loaderWrap.fadeOut(200);
                    },
                    success: function (res) {
                        nextPosts.append(res);
                        $(".ajaxAddedElement").fadeIn(600);
                        currentPostCount = offset + 2;
                    },
                    error: function (error) {
                    }
                });
            });
        }

        $(document).on('scroll', function () {
            const container = $('.m-projects__row');
            if ((container.length > 0) && (postCount >= currentPostCount)) {
                const containerHeight = container.outerHeight();
                const containerOffsetTop = container.offset().top;
                const pageHeight = window.innerHeight;
                const scrollTop = window.scrollY;
                const scrolledPx = pageHeight + scrollTop;

                if (scrolledPx >= containerHeight + containerOffsetTop - 150) {
                    if (infinityLoadMore) {
                        infinityLoadMore = false;
                        loaderWrap.fadeOut(200);
                        loadAjaxProjects().then(() => {
                            infinityLoadMore = true;
                        });
                    }
                }
            }
        });
    }
});
