jQuery($ => {
    // SVGInject
    SVGInject.setOptions({
        onFail: function (img) {
            img.classList.remove("injectable");
        },
    });
    SVGInject(document.querySelectorAll("img.injectable"));

    // AOS
    AOS.init({
        duration: 400,
        easing: "ease",
        once: true
    });

    const navBtn = $(".mobileNavBtn");
    const navMenu = $(".mobileNavMenu");
    const htmlBody = $("html, body");
    const header = $('.m-header');

    // mobile nav
    function changeText(el, text) {
        el.fadeOut(200);
        setTimeout(() => {
            el.text(text).fadeIn(200);
        }, 250);
    }

    // init midnight
    header.midnight();

    $('body').on("click", '.mobileNavBtn', function(e) {
        e.preventDefault();

        let active = $(this).hasClass("active") ? true : false;
        let closeText = $(this).data('alt-text') ? $(this).data('alt-text') : "Close";

        if (active) {
            $(this).removeClass("active");
            navMenu.removeClass("active");
            htmlBody.removeClass("blocked");
            header.removeClass('white-color');
            changeText($(this), "Menu");
        } else {
            $(this).addClass("active");
            navMenu.addClass("active");
            htmlBody.addClass("blocked");
            header.addClass('white-color');
            changeText($(this), closeText);
        }
    });

    $(window).on("resize", function(e) {
        if ($(this).outerWidth() > 576) {
            navBtn.removeClass("active");
            navMenu.removeClass("active");
            htmlBody.removeClass("blocked");
        }
    });

    // hover - box
    const hoverBox = $(".e-hover");
    const arrowWidth = $(".e-hover__arrow").outerWidth() / 3;

    $('body').on("mousemove", '.e-hover', function(e) {
        const relX = e.pageX - $(this).offset().left;
        const relY = e.pageY - $(this).offset().top;

        $(this).find(".e-hover__arrow").css({
            left: relX - arrowWidth,
            top: relY - 20,
            opacity: 1,
            visibility: "visible"
        });
    });

    $('body').on("mouseleave", '.e-hover', function(e) {
        $(this).find(".e-hover__arrow").css({
            opacity: 0,
            visibility: "hidden"
        });
    });

    // projects - read more
    const moreBtn = $(".m-top__read-more");
    const moreText = $(".m-top__additional");

    moreBtn.on("click", function(e) {
        e.preventDefault();

        moreText.slideToggle(300);
        $(this).toggleClass('open');

        if ($(this).hasClass('open') ) {
            $(this).html(`- - - <span>Read less</span>`);
        } else {
            $(this).html(`+ + + <span>Read more</span>`);
        }
    });

    $(".m-featured__row").masonry({
        itemSelector: ".m-featured__col"
    });

    // iOS input zoom fix
    const addMaximumScaleToMetaViewport = () => {
        const el = document.querySelector("meta[name=viewport]");
        if (el !== null) {
            let content = el.getAttribute("content");
            let re = /maximum\-scale=[0-9\.]+/g;
            if (re.test(content)) {
                content = content.replace(re, "maximum-scale=1.0");
            } else {
                content = [content, "maximum-scale=1.0"].join(", ");
            }
            el.setAttribute("content", content);
        }
    };
    const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
    const checkIsIOS = () =>
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (checkIsIOS()) {
        disableIosTextFieldZoom();
    }

    $(window).on('scroll', function(e) {
        var theta = ($(window).scrollTop() / 400.0) % (Math.PI * 2);
        $(".e-float-cta__desc").css({ transform: "rotate(" + theta + "rad)" });
    });

    // cookies
    function setCookie(name,value,days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    function getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    if (getCookie('cookies_msg') != 1) {
        $('.m-cookies').fadeIn('slow');
    }
    $('.m-cookies__close').on('click', function (e) {
        e.preventDefault();
        $('.m-cookies').fadeOut('slow');
        setCookie('cookies_msg', 1, 30);
        return false;
    });
});
